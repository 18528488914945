import React from 'react';

import { Outlet, useNavigate, useLocation, matchPath } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';
import Login from './Features/Login/Login';
import LoginWithUrl from './Shared/Components/LoginWithUrl/LoginWithUrl';
import { jwtToken } from './Models/Types/JwtToken';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const App = () => {
  const location = useLocation();
  let Component = <Login />;

  // When the MPZ shortcut is used, login with the userName parameter.
  if (matchPath(location.pathname, '/MPZ')) {
    Component = <LoginWithUrl />;
  }

  // Check if it's still a valid session, if so show the Outlet.
  if (localStorage.getItem('userToken') !== null) {
    const { exp }: jwtToken = jwtDecode(localStorage.getItem('userToken') ?? '');
    const now: EpochTimeStamp = new Date().getTime() / 1000;

    if (exp > now) {
      Component = <Outlet />;
    }
  }

  // When viewing a detail page always show the Outlet.
  if (matchPath('/productenregister/detail/*', location.pathname)) {
    Component = <Outlet />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <div className="app">
        {Component}
      </div>
    </QueryClientProvider>
  );
};

export default App;
